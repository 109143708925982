const firebaseConfig = {
  apiKey: "AIzaSyD30u_cMFgGTbAXtxD0xRQTGVWmSbG01yg",
  authDomain: "chatdemo2-12126.firebaseapp.com",
  databaseURL: "https://chatdemo2-12126.firebaseio.com",
  projectId: "chatdemo2-12126",
  storageBucket: "chatdemo2-12126.appspot.com",
  messagingSenderId: "448366996389",
  appId: "1:448366996389:web:b66086c83a6d0ab80e564c",
  measurementId: "G-W1HC7PNJJB"
};
export default firebaseConfig;